import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Memorial Day Murph`}</strong></p>
    <p>{`1 Mile Run`}</p>
    <p>{`100-Pullups`}</p>
    <p>{`200-Pushups`}</p>
    <p>{`300-Squats`}</p>
    <p>{`1 Mile Run`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`pullups, pushups & squats can be partitioned out as needed`}</em></p>
    <p><em parentName="p">{`*`}{`If you have a weight vest wear it!`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Heats start at 10:30 and go on the half hour until everyone
finishes.  The 10:30 & 11:00 heats are full.  11:30 & 12:00 are the
current options.  All other class times and open gym are cancelled for
today.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`If you ordered a Murph shirt please pay and pick it up today!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Sunday, June 3rd we will have a Mobility for Athletic Performance
workshop from 1:45-3:00pm.  This workshop will be taught by Stephanie
Bellissimo and will focus on floor work and locomotion for CrossFit
athletes.  This intro class is free to all CrossFit the Ville members
and just \\$15 for non members.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      